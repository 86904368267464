import { Button } from "~/components/Button";
import type { GetFlywheelStepData } from "~/hooks/flywheel/use-get-flywheel";

import type React from "react";

type PickedFlywheelStepData = Pick<GetFlywheelStepData, "alias" | "name" | "id">;
interface StepNavigationProps {
  onClick: (step: PickedFlywheelStepData) => void;
  selectedStep: PickedFlywheelStepData | null;
  steps: PickedFlywheelStepData[] | null;
}
export const StepNavigation: React.FC<StepNavigationProps> = ({
  onClick, selectedStep, steps
}) => {
  return (
    <div
      className="flex gap-2 items-center top-0 pb-4 overflow-x-auto px-2 sm:px-0  *:min-w-0 *:shrink-0 w-full md:flex-wrap"
    >
      {selectedStep && steps?.map(step => (
        <Button
          key={step.id}
          title={step.alias ?? step.name}
          className={`px-2.5 py-1 lg:py-2 text-xs font-normal ${step.id === selectedStep.id ? "bg-brand-cold-metal-900" : "bg-brand-cold-metal-50 text-brand-cold-metal-900"}`}
          onClick={() => onClick(step)}
        />
      ))}
    </div>
  );
};