import { ReportingCycle } from "~/components/onboarding/content/ReportingCycle";
import { SpeakToTeam } from "~/components/onboarding/content/SpeakToTeam";
import type {
  OnboardingStep,
  ChangeStep
} from "~/components/onboarding/OnboardingProgressBar";
import { SelectedStepProvider } from "~/contexts/SelectedStepContext";

import {
  CompanyDetails,
  UserDetails,
  FlywheelGoal,
  FlywheelGoalProgress,
  FlywheelIntro,
  MetricsAndTargets
} from "./content";

export interface OnboardingContentProps {
  currentStep: OnboardingStep;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

export interface OnboardingContentStepProps {
  onNextStep: () => void;
  onBack?: () => void;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

interface Content {
  title: string;
  subTitle?: string;
  body?: string[];
}

export const CONTENT: Record<OnboardingStep, Content> = {
  user_details: {
    title: "Welcome to Roda",
    subTitle: "Tell us a few details about yourself to get started"
  },
  company_details: {
    title: "Your organisation",
    subTitle: "Tell us about some key organisation details"
  },
  flywheel_intro: {
    title: "Introducing the flywheel",
    subTitle: "Let's explore the key features of a flywheel"
  },
  speak_to_team: {
    title: "Let's get started",
    subTitle: "First let's choose the best way for you to start using Roda's flywheel to drive your business success."
  },
  reporting_cycle: {
    title: "Set up your reporting cycle",
    body: [ "Each flywheel focuses on a single goal with a target completion date, which we'll set now. Flywheel reporting follows a quarterly cycle with monthly progress updates.", "Your target completion date for your flywheel can be up to 12 months from this month. We advise that you align the end-date to the finish of your internal reporting cycles (e.g. your fiscal calendar)" ]
  },
  flywheel_goal: { title: "Setting a goal" },
  flywheel_goal_progress: { title: "Progress towards goal" },
  metrics_targets: { title: "Steps and metrics" }
};

export const OnboardingContent: React.FC<OnboardingContentProps> = ({
  currentStep, changeStep, createFlywheelMode
}) => {
  return (
    <>
      <div className="flex flex-col flex-1">

        {currentStep === "user_details" && (
          <UserDetails
            onNextStep={() => changeStep("company_details")}
            changeStep={changeStep}
          />
        )}

        {currentStep === "company_details" && (
          <CompanyDetails
            onNextStep={() => changeStep("flywheel_intro")}
            changeStep={changeStep}
            onBack={() => changeStep("user_details")}
          />
        )}

        {currentStep === "flywheel_intro" && (
          <FlywheelIntro
            onNextStep={() => changeStep("speak_to_team")}
            changeStep={changeStep}
            onBack={!createFlywheelMode ? () => changeStep("company_details") : undefined}
            createFlywheelMode={createFlywheelMode}
            overrideTitle={createFlywheelMode ? "Set up a new flywheel" : undefined}
          />
        )}

        {currentStep === "speak_to_team" && (
          <SpeakToTeam
            onNextStep={() => changeStep("reporting_cycle")}
            changeStep={changeStep}
            onBack={() => changeStep("flywheel_intro")}
          />
        )}

        {currentStep === "reporting_cycle" && (
          <ReportingCycle
            onNextStep={() => changeStep("flywheel_goal")}
            changeStep={changeStep}
            onBack={() => changeStep("speak_to_team")}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "flywheel_goal" && (
          <FlywheelGoal
            onNextStep={() => changeStep("flywheel_goal_progress")}
            changeStep={changeStep}
            onBack={() => changeStep("reporting_cycle")}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "flywheel_goal_progress" && (
          <FlywheelGoalProgress
            onNextStep={() => changeStep("metrics_targets")}
            changeStep={changeStep}
            onBack={() => changeStep("flywheel_goal")}
            createFlywheelMode={createFlywheelMode}
          />
        )}

        {currentStep === "metrics_targets" && (
          <SelectedStepProvider>
            <MetricsAndTargets
              onNextStep={() => null}
              changeStep={changeStep}
              onBack={() => changeStep("flywheel_goal_progress")}
              createFlywheelMode={createFlywheelMode}
            />
          </SelectedStepProvider>
        )}
      </div>
    </>

  );
};