import Half_Flywheel from "~/assets/half_flywheel.svg";
import Logo from "~/assets/logo.svg";
import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import type { ChangeStep } from "~/components/onboarding/OnboardingProgressBar";
import { OnboardingProgressBar } from "~/components/onboarding/OnboardingProgressBar";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { useAuth } from "~/contexts/AuthContext";
import { useOnboardingState } from "~/contexts/OnboardingContext/OnboardingContext";
import { useIsMobile } from "~/hooks/useIsMobile";

import { CONTENT } from "./OnboardingContent";

import type React from "react";

export interface OnboardingContentWrapperProps {
  changeStep: ChangeStep;
  /** (appears as bottom on mobile) */
  leftContent: JSX.Element;
  /** (appears as top on mobile) */
  rightContent?: JSX.Element;
  hideTitle?: boolean;
  nonOnboardingMode?: boolean; // Re-using some of the onboarding components outside of onboarding
  overrideTitle?: string;
  overrideSubtitle?: string;
  createFlywheelMode?: boolean;
  hideLogo?: boolean;
}

export const OnboardingContentWrapper: React.FC<OnboardingContentWrapperProps> = ({
  changeStep, leftContent, rightContent, hideTitle, nonOnboardingMode, overrideTitle, overrideSubtitle, createFlywheelMode, hideLogo
}) => {
  const { step } = useOnboardingState();
  const isMobile = useIsMobile();
  const { logout } = useAuth();

  return (
    <div className="relative flex flex-1 bg-brand-cold-metal-50 flex-col-reverse lg:flex-row [--top-h:300px] pt-[--top-h] lg:pt-0 z-10">

      {/** Left (OR bottom) content */}
      <div className="md:h-screen md:overflow-scroll flex flex-col z-10 bg-white flex-1 px-3 w-full rounded-t-2xl lg:rounded-t-none lg:w-1/2">

        {/** Logo (Desktop) */}
        {!isMobile && (
          <div className="pl-5 pt-5 mb-8">
            {!hideLogo && (
              <img
                src={Logo}
                alt="Logo"
              />
            )}
          </div>
        )}

        <div className={`flex flex-1 flex-col items-center ${isMobile ? `w-full px-5 pb-5 ${nonOnboardingMode ? "pt-5" : ""}` : "w-[min(100%,600px)] mx-auto pt-[0%]"} justify-center self-center z-10`}>
          {!nonOnboardingMode && (
            <OnboardingProgressBar
              step={step}
              changeStep={changeStep}
              createFlywheelMode={createFlywheelMode}
            />
          )}

          {/** Current step's title & subtitle */}
          {!hideTitle && (
            <div className="flex flex-col gap-y-2 mb-3 w-full">
              <p className={`font-bold ${isMobile ? "text-xl" : "text-2xl"}`}>{overrideTitle || CONTENT[ step ].title}</p>

              {!!overrideSubtitle || !!CONTENT[ step ].subTitle && (
                <p className={`${isMobile ? "text-xs" : "text-base"} opacity-80`}>{overrideSubtitle || CONTENT[ step ].subTitle}</p>
              )}

              {CONTENT[ step ].body && (
                <div className="flex flex-col gap-y-2 mb-4">
                  {CONTENT[ step ].body?.map(bodyItem => (
                    <p
                      key={bodyItem}
                      className="text-sm opacity-80"
                    >
                      {bodyItem}
                    </p>
                  ))}
                </div>
              )}

            </div>
          )}

          {leftContent}
        </div>
      </div>

      {/** Right (OR top) graphic */}
      <div className="flex flex-col lg:w-1/2 lg:h-full fixed top-0 inset-x-0 w-full h-[--top-h] lg:items-center md:self-start lg:relative">
        {/* Right content or roda logo svg background */}
        {/** Logo for mobile */}
        {isMobile && (
          <>
            <div className="absolute pl-5 pt-5 top-0 left-0 z-20 w-[90px]">
              {!nonOnboardingMode && !createFlywheelMode && (
                <img
                  src={Logo}
                  alt="Logo"
                />
              )}
            </div>

          </>
        )}

        {rightContent ? (
          <div className={`relative flex-1 self-stretch flex ${!isMobile ? "" : "pt-[60px]"}`}>
            {rightContent}
          </div>
        ) : (
          <img
            src={Half_Flywheel}
            className={`absolute inset-y-0 object-cover ${isMobile ? "w-[50%] h-[calc(var(--top-h)+32px)]" : "w-full h-full"}`}
          />
        )}

        {/* Logout button */}

        <div className="absolute pr-5 pt-5 flex flex-row gap-x-3 top-0 right-0">
          <Button
            title="Speak to the team"
            onClick={() => window.location.href = "https://calendly.com/matt-spry-rodastrategy/roda-set-up-support-call"}
            className="bg-brand-cold-metal-200 hover:contrast-75 w-full text-brand-cold-metal-900"
          />

          {!nonOnboardingMode && !createFlywheelMode && (
            <VerticalDotMenu buttonClassName=" !px-0 m-0 text-brand-cold-metal-600 hover:text-black">
              <button
                onClick={logout}
              >
                Sign out
              </button>
            </VerticalDotMenu>

          )}
        </div>

      </div>
    </div>
  );
};
