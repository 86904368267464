/* eslint-disable @typescript-eslint/no-explicit-any */
import tailwindBrandColours from "@roda/shared/tailwindBrandColours";
import {
  FlywheelTemplateUnitTypeLabelEnum,
  FlywheelTemplateReportingWindowTimingEnum
} from "@roda/shared/types";
import { getDateRangeWeekBounds } from "@roda/shared/utils/getDateRangeWeekBounds";
import clsx from "clsx";
import {
  useCallback,
  useMemo,
  useState
} from "react";
import { Chart } from "react-chartjs-2";
import {
  Navigate,
  useLocation,
  useParams
} from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { SelectInput } from "~/components/form";
import { InfoTooltip } from "~/components/InfoTooltip";
import { MetricCard } from "~/components/metric/MetricCard";
import { MetricCheckInBadge } from "~/components/metric/MetricCheckInBadge";
import { SaveImageMenuItem } from "~/components/metric/SaveImageMenuItem";
import { SaveImageButton } from "~/components/SaveImageButton";
import { Spacer } from "~/components/Spacer";
import { UpdateNotes } from "~/components/UpdateNotes";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { routes } from "~/constants/routes";
import { ImageCaptureTarget } from "~/contexts/ImageCaptureContext";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { backgroundBarPlugin } from "~/utils/barBackgroundPlugin";
import dayjs from "~/utils/dayjs";
import { getArrayOfFlywheelWeeks } from "~/utils/getArrayOfFlywheelWeeks";
import { getBarColour } from "~/utils/getBarColour";
import { getLabelBeingHovered } from "~/utils/getLabelBeingHovered";
import { getUserDisplayName } from "~/utils/getUserDisplayName";
import { getWeekMetricTarget } from "~/utils/getWeekMetricTarget";
import { getWeekMetricUpdate } from "~/utils/getWeekMetricUpdate";
import { getWeekNumber } from "~/utils/getWeekNumber";
import { handleBarChartHoverForWeek } from "~/utils/handleBarChartHover";
import { weekIsHealthy } from "~/utils/weekIsHealthy";

import type {
  Flywheel,
  Metric as MetricType,
  Step
} from "@roda/graphql/genql";
import type {
  ActiveElement,
  Chart as ChartJS,
  ChartEvent,
  ScriptableContext,
  Tick
} from "chart.js/auto";

const getMetricFromFlywheel = (flywheel: Flywheel, stepId: Step["id"], metricId: MetricType["id"]) => {
  return flywheel?.steps?.find(step => step.id === stepId)?.metrics?.find(metric => metric.id === metricId);
};

export const MetricDetail = () => {
  const params = useParams() as { stepId: Step["id"], metricId: MetricType["id"] };
  const { state: locationState } = useLocation();
  const { metric: metricLocationState } = (locationState ?? {}) as { metric?: MetricType };

  const {
    flywheel: flywheelCtx, flywheelSubgoals, flywheelStartWeek, flywheelCycleNotStarted
  } = useSelectedFlywheel();

  const metric = metricLocationState ?? flywheelCtx ? getMetricFromFlywheel(flywheelCtx!, params.stepId, params.metricId) : null;
  const { currentCompany } = useRodaAdminCompaniesContext();
  // We get the week, update, quarter, and weeks within the quarter based on the week the user chooses - by default, the current week
  const [ selectedWeekStart, setSelectedWeekStart ] = useState(dayjs().subtract(1, "week").startOf("isoWeek"));
  const selectedWeekEnd = useMemo(() => selectedWeekStart.endOf("isoWeek"), [ selectedWeekStart ]);
  const selectedSubgoal = useMemo(() => flywheelSubgoals?.find(subgoal => selectedWeekStart.isBetween(dayjs(subgoal.startDate).startOf("isoWeek"), dayjs(subgoal.endDate).endOf("isoWeek"), "days", "[)")), [ flywheelSubgoals, selectedWeekStart ]);
  const { startDate, endDate } = useMemo(() => getDateRangeWeekBounds(dayjs(selectedSubgoal?.startDate), dayjs(selectedSubgoal?.endDate)), [ selectedSubgoal?.endDate, selectedSubgoal?.startDate ]);
  const weeksInQuarter = useMemo(() => endDate.diff(startDate, "weeks") + 1, [ endDate, startDate ]);
  // A quarter always has 13 or 14 weeks
  const selectedQuarterWeeks = useMemo(() => Array(weeksInQuarter).fill(0).map((_, i) => (dayjs(startDate).add(i, "weeks"))), [ startDate, weeksInQuarter ]);
  const selectedQuarterWeeksLabels = useMemo(() => selectedQuarterWeeks.map(week => `W${week.diff(dayjs(flywheelStartWeek), "week") + 1}`), [ flywheelStartWeek, selectedQuarterWeeks ]);
  const currentMetricTarget = metric?.targets?.find(target => target.isCurrent);

  const selectedWeekUpdate = useMemo(() => metric?.metricUpdates ? getWeekMetricUpdate(metric?.metricUpdates, selectedWeekStart, selectedWeekEnd) : null, [
    metric?.metricUpdates,
    selectedWeekEnd,
    selectedWeekStart
  ]);

  // Change the selected week when the user clicks on a week in the chart
  const handleChartClick = useCallback((
    event: ChartEvent,
    elements: ActiveElement[],
    chart: ChartJS
  ) => {
    // Get the label that we clicked on (e.g W1, W2)
    const label = getLabelBeingHovered(event, chart);

    if (!label) {
      return;
    }

    if (!flywheelStartWeek) return null;

    // Find the index of the selected week
    const selectedWeekIdx = selectedQuarterWeeksLabels.findIndex(week => week === label);

    // Return if there was no match
    if (selectedWeekIdx === -1) return null;
    // Find the actual week object from selectedQuarterWeeks
    const selectedWeek = selectedQuarterWeeks[ selectedWeekIdx ];

    // Check that the week they're trying to select is NOT in the future (available for check-in)
    if (!dayjs(selectedWeek).isAfter(dayjs().subtract(1, "week"), "week")) {
      setSelectedWeekStart(dayjs(selectedWeek));
    }
  }, [
    flywheelStartWeek,
    selectedQuarterWeeks,
    selectedQuarterWeeksLabels
  ]);

  // Build an array of weeks of the flywheel - used in the week selector
  const weeksOfFlywheelYearSelectOptions = useMemo(() => flywheelCtx ? getArrayOfFlywheelWeeks(flywheelStartWeek, true) : [], [ flywheelCtx, flywheelStartWeek ]);
  const isCumulativePercentage = useMemo(() => metric?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE && metric.unitDisplay === "quarterly", [ metric?.unitDisplay, metric?.unitTypeLabel ]);

  /**
   * We only show cumulative totals for:
   * quarterly targets
   * excluding quarterly percentages
   * and when the reporting window timing is NOT quarter to date (hence showing a series of updates)
   */
  const shouldShowCumulativeTotal = useMemo(() => !isCumulativePercentage && metric?.unitDisplay === "quarterly" && metric?.reportingWindowTiming !== FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE, [
    isCumulativePercentage,
    metric?.reportingWindowTiming,
    metric?.unitDisplay
  ]);

  // Show the check-in due UI if there isn't an update for the selected week and it's in the past
  const isCheckInDue = useMemo(() => !flywheelCycleNotStarted && selectedWeekStart.isBefore(dayjs().subtract(1, "week").endOf("isoWeek")) && !selectedWeekUpdate, [
    selectedWeekStart,
    flywheelCycleNotStarted,
    selectedWeekUpdate
  ]);

  if (!metric) return (
    <Navigate
      to={routes.dashboard(currentCompany ? currentCompany.id : undefined)}
      replace
    />
  );

  // Map over all the weeks in the selected quarter, and calculate the cumulative total of metric
  // updates for the quarter up to each week
  const cumulativeQuarterlyMetricUpdateData = selectedQuarterWeeks.map((week, i) => {
    // If the week if after last week (the week which check-in is due for), don't show cumulative data
    const currentCheckInWeek = dayjs().startOf("isoWeek").subtract(1, "week");

    if (week.isAfter(currentCheckInWeek, "weeks")) return 0;

    // If it's a cumulative percentage or reportingTimingWindow is "quarter to date" then cumulativeTotal should be 0
    // Otherwise use reduce to sum the metric update values for the weeks in the quarter up to the given week
    // The slice here makes sure that we're only summing data for weeks before the current week each time

    const cumulativeTotal = isCumulativePercentage || metric.reportingWindowTiming === FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE ? "0" : String(selectedQuarterWeeks.slice(0, i).reduce((prev, curr) => {
      if (!metric.metricUpdates) return 0;

      // Find the metric update for the given week in the quarter
      const weekMetric = getWeekMetricUpdate(metric.metricUpdates, curr, curr.endOf("isoWeek"));
      // Get the metric update's value if it exists, or 0 if there isn't one
      const weekMetricValue = weekMetric?.value || 0;

      // Add this week's metric value to the cumulative total so far
      return prev + +weekMetricValue;
    }, 0));

    return cumulativeTotal;
  });

  // Gets the background colour for the chart based on check in status
  const getChartBackgroundColour = (index: number) => {
    const indexWeek = dayjs(selectedQuarterWeeks[ index ]).startOf("isoWeek");

    // if the current week is the same as the week in the chart and check in is due, and the index is not out of av options for the user. show the check-in due colour
    if (indexWeek.isSame(selectedWeekStart, "week") && !selectedWeekUpdate && selectedQuarterWeeks[ index ] !== undefined) {
      return tailwindBrandColours.brand[ "check-in-due-50" ];
    } else return "#F8F9FC";
  };

  return (
    <ImageCaptureTarget captureId="metric-all">
      <div
        className="flex flex-col h-auto xl:h-step-container gap-5 md:gap-6 lg:gap-8 max-md:mx-auto"
      >

        <div className="flex gap-6 flex-wrap items-center pt-10 text-pretty">

          <div className="flex flex-row gap-1">
            <p className="text-xl 2xl:text-2xl font-semibold text-balance">{metric.name}</p>

            <InfoTooltip
              text={metric.unitDescription}
              slideDirection="top"
            />
          </div>

          <Spacer />

          {!flywheelCycleNotStarted && (

            <div className="min-w-full md:min-w-[30%] shrink-0">
              <SelectInput
                disabled={weeksOfFlywheelYearSelectOptions.length === 0}
                value={selectedWeekStart.format("YYYY-MM-DD")}
                options={weeksOfFlywheelYearSelectOptions}
                renderOptionLabel={value => `W.${getWeekNumber({
                  date: value,
                  flywheelStartWeek
                })} ${dayjs(value).format("MMMM DD")}`}
                renderOptionBadge={value => (
                  <MetricCheckInBadge
                    flywheelCycleNotStarted={flywheelCycleNotStarted}
                    metric={metric}
                    weekStart={dayjs(value).startOf("isoWeek")}
                    weekEnd={dayjs(value).endOf("isoWeek")}
                  />
                )}
                placeholder="Choose a week"
                onValueChange={value => {
                  setSelectedWeekStart(dayjs(value).startOf("isoWeek"));
                }}
              />
            </div>
          )}

          <SaveImageButton
            captureId="metric-all"
            className="mobile:hidden shrink-0"
            html2canvasOptions={{
              allowTaint: true,
              useCORS: true,
              onclone(_: any, element: { style: { padding: string; }; querySelector: (arg0: string) => any; }) {
                // add some padding to the otherwise padding-less container
                // (!) this doesn't give the ChartJS a chance to resize
                // properly, therefore pushes it (!!!), so minimise padding where possible (argh!)
                element.style.padding = "10px 10px 0 10px";
                // fixes issue where tiles are stacked on top of each other and
                // pushes elements off-screen in output image
                element.querySelector("[data-capture-target=\"metric\"]")!.parentElement!.style.flexDirection = "row";
              },
              // deals with weird padding and name cut-off issue
              y: 40
            }}
          />

        </div>

        <div
          className="flex flex-col md:flex-row md:*:min-w-0 justify-between gap-8 *:min-h-[250px]"
        >
          <ImageCaptureTarget captureId="metric">
            <div className={clsx(`border-[1.5px] flex-wrap border-solid ${isCheckInDue ? "border-brand-check-in-due-100" : "border-brand-cold-metal-200"} flex flex-col rounded-xl min-h-[250px] max-h-[400px] w-full min-w-[min(100%,300px)] text-left flex-1`, isCheckInDue ? "bg-[#FAFCFF]" : "bg-white")}>
              <MetricCard
                metric={metric}
                hideTitle
                selectedWeekStart={selectedWeekStart}
                showUser={false}
              />
            </div>
          </ImageCaptureTarget>

          <div
            className={`bg-white flex flex-col rounded-xl min-h-[250px] max-h-[400px] w-full min-w-[min(100%,300px)] text-left flex-1 border-[1.5px] border-solid ${selectedWeekUpdate?.notes ? "justify-between" : "justify-end"}`}
          >
            <UpdateNotes
              reportedAt={selectedWeekUpdate?.updatedAt}
              reportedBy={selectedWeekUpdate?.updatedBy}
              notes={selectedWeekUpdate?.notes}
            />
          </div>
        </div>

        {!flywheelCycleNotStarted && (

          <ImageCaptureTarget captureId="metric-chart">
            <div
              className="bg-white border-[1.5px] border-solid border-brand-cold-metal-200 flex flex-col rounded-lg p-6 grow min-h-[250px] max-h-[500px]"
            >
              <div className="flex justify-between">
                <p className="text-lg font-semibold">Performance history</p>

                <VerticalDotMenu
                  buttonClassName="mobile:hidden m-0 -mr-4 md:-mr-8 -mt-1 text-brand-cold-metal-400 hover:text-black"
                >
                  <SaveImageMenuItem
                    captureId="metric-chart"
                    html2canvasOptions={{
                      onclone(_: any, element: { style: { minHeight: string; maxHeight: string; height: string; }; }) {
                        // min/max height classes seem to be tripping up output
                        element.style.minHeight = "unset";
                        element.style.maxHeight = "unset";
                        element.style.height = document.querySelector("[data-capture-target=\"metric-chart\"]")!.clientHeight + "px";
                      }
                    }}
                  />
                </VerticalDotMenu>
              </div>

              <div className="p-4 h-full w-full flex flex-center">
                <Chart
                  id="performance-history-chart"
                  type="bar"
                  data={{
                    labels: selectedQuarterWeeksLabels,
                    datasets: [
                      {
                        type: "line",
                        label: "Target",
                        data: selectedQuarterWeeks.map(week => {
                          if (metric.metricUpdates) {
                            const weekMetricUpdate = getWeekMetricUpdate(metric.metricUpdates, week, week.endOf("isoWeek"));

                            if (weekMetricUpdate) {
                              return weekMetricUpdate?.metricTarget?.target;
                            }
                          }
                          const weekTarget = getWeekMetricTarget(metric.targets, week);

                          return weekTarget?.target || currentMetricTarget?.target || "0";
                        }),
                        borderColor: "#4B5563",
                        backgroundColor: "#4B5563",
                        borderDash: [ 5, 5 ],
                        borderWidth: 0.5,
                        pointStyle: "circle",
                        pointRadius: 1.5,
                        order: 1,
                        tension: 0.1 // Add tension to make the line curvy
                      },
                      {
                        type: "bar",
                        label: "Cumulative total",
                        data: cumulativeQuarterlyMetricUpdateData,
                        backgroundColor: (ctx: ScriptableContext<"bar">) => {
                          const barWeekStart = selectedQuarterWeeks[ ctx.dataIndex ];
                          const cumulativeValueForWeek = cumulativeQuarterlyMetricUpdateData[ ctx.dataIndex ];
                          const isHealthy = weekIsHealthy(metric, selectedWeekStart, selectedQuarterWeeks, +cumulativeValueForWeek, selectedWeekUpdate);

                          return getBarColour(barWeekStart, selectedWeekStart, selectedWeekEnd, isCheckInDue, isHealthy, true);
                        },
                        borderRadius: 5,
                        order: 2,
                        // We only show the cumulative data if it's a quarterly metric
                        hidden: !shouldShowCumulativeTotal
                      },
                      {
                        type: "bar",
                        label: "Check-in value",
                        data: selectedQuarterWeeks.map(week => {
                          if (!metric.metricUpdates) return "0";
                          const weekMetricUpdate = getWeekMetricUpdate(metric.metricUpdates, week, week.endOf("isoWeek"));

                          return weekMetricUpdate?.value || undefined;
                        }),
                        backgroundColor: (ctx: ScriptableContext<"bar">) => {
                          const barWeekStart = selectedQuarterWeeks[ ctx.dataIndex ];

                          return getBarColour(barWeekStart, selectedWeekStart, selectedWeekEnd, isCheckInDue, selectedWeekUpdate?.isHealthy);
                        },
                        minBarLength: 3,
                        borderRadius: 5,
                        order: 3
                      }
                    ]
                  }}
                  plugins={[ backgroundBarPlugin ]}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        border: { display: false },
                        ticks: {
                          // Only show y axis values if they match one of the displayed targets
                          callback: (tickValue: string | number) => {
                            const metricTargets = selectedQuarterWeeks.map(week => getWeekMetricTarget(metric.targets, week));

                            if (metricTargets.find(target => target?.target === tickValue.toString())) {
                              return tickValue;
                            }

                            return "";
                          }
                        },
                        grid: { display: false },
                        stacked: true
                      },
                      x: {
                        grid: { display: false },
                        border: { display: false },
                        stacked: true,
                        offset: true,
                        ticks: {
                          font: (ctx: {index: number, tick: Tick}) => {
                            const tickWeek = selectedQuarterWeeks[ ctx.index ];

                            if (tickWeek.isSame(selectedWeekStart, "week")) {
                              return {
                                weight: "bold",
                                size: 16
                              };
                            }
                          }
                        }
                      }
                    },
                    plugins: {
                      backgroundBar: {
                        borderSkipped: true,
                        colour: getChartBackgroundColour
                      },
                      legend: { display: false },
                      title: { display: false }
                    },
                    onClick: handleChartClick,
                    onHover: (event: ChartEvent, _elements: ActiveElement[], chart: ChartJS) => handleBarChartHoverForWeek(event, chart, flywheelStartWeek)
                  }}
                />
              </div>
            </div>
          </ImageCaptureTarget>
        )}

        {metric.owner && (
          <div
            className="w-full pr-5 pb-5"
            id="metric-owner-section"
          >
            <p className="text-xs text-brand-cold-metal-500 font-semibold text-right">Assigned to</p>

            <div className="flex flex-row gap-2 items-center text-sm mt-1 justify-end">
              <Avatar
                user={metric.owner}
                px={24}
              />

              <p>{getUserDisplayName(metric.owner)}</p>
            </div>
          </div>
        )}

      </div>
    </ImageCaptureTarget>
  );
};