import { OnboardingProgressStep } from "~/components/onboarding/OnboardingProgressStep";
import { useIsMobile } from "~/hooks/useIsMobile";

export type ChangeStep = (step: OnboardingStep) => void;
export type OnboardingStep = "user_details" | "company_details" | "flywheel_intro" | "speak_to_team" | "reporting_cycle" | "flywheel_goal" | "flywheel_goal_progress" | "metrics_targets";

interface OnboardingProgressBarProps {
  step: OnboardingStep;
  changeStep: ChangeStep;
  createFlywheelMode?: boolean;
}

export interface OnboardingStepDetails {
  step: OnboardingStep;
  title: string;
}

const onboardingSteps: OnboardingStepDetails[] = [
  {
    step: "user_details",
    title: "USER SETUP"
  },
  {
    step: "company_details",
    title: "COMPANY SETUP"
  },
  {
    step: "flywheel_intro",
    title: "FLYWHEEL SETUP"
  },
  {
    step: "speak_to_team",
    title: "SPEAK TO THE TEAM"
  },
  {
    step: "reporting_cycle",
    title: "REPORTING CYCLE"
  },
  {
    step: "flywheel_goal",
    title: "FLYWHEEL GOAL"
  },
  {
    step: "flywheel_goal_progress",
    title: "GOAL PROGRESS"
  },
  {
    step: "metrics_targets",
    title: "METRICS & TARGETS"
  }
];

const createFlywheelSteps: OnboardingStepDetails[] = [
  {
    step: "flywheel_intro",
    title: "FLYWHEEL SETUP"
  },
  {
    step: "reporting_cycle",
    title: "REPORTING CYCLE"
  },
  {
    step: "flywheel_goal",
    title: "FLYWHEEL GOAL"
  },
  {
    step: "flywheel_goal_progress",
    title: "GOAL PROGRESS"
  },
  {
    step: "metrics_targets",
    title: "METRICS & TARGETS"
  }
];

export const OnboardingProgressBar: React.FC<OnboardingProgressBarProps> = ({
  step, changeStep, createFlywheelMode
}) => {
  // Use different steps if we're on createFlywheelMode
  const progressSteps = createFlywheelMode ? createFlywheelSteps : onboardingSteps;
  const currentStepIndex = progressSteps.findIndex(stepDetails => stepDetails.step === step);
  const isMobile = useIsMobile();

  return (
    <div className={`flex flex-row justify-start items-center ${isMobile ? "my-5" : "mb-5"} w-full`}>

      {progressSteps.map((progressStep, index) => (
        <OnboardingProgressStep
          key={progressStep.step}
          stepDetails={progressStep}
          changeStep={changeStep}
          shouldBeEmpty={index > currentStepIndex}
          totalSteps={progressSteps.length}
        />
      ))}
    </div>
  );
};