import tailwindBrandColours from "@roda/shared/tailwindBrandColours";
import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";
import { getFirstMondayFromStartDate } from "@roda/shared/utils/getFirstMondayFromStartDate";
import {
  useCallback,
  useMemo,
  useState
} from "react";
import { Chart } from "react-chartjs-2";

import { Avatar } from "~/components/Avatar";
import { FlywheelGoalCheckInBadge } from "~/components/flywheel-goal/FlywheelGoalCheckInBadge";
import { FlywheelGoalTotalCard } from "~/components/flywheel-goal/FlywheelGoalTotalCard";
import { FlywheelSubgoalCard } from "~/components/flywheel-goal/FlywheelSubgoalCard";
import { SelectInput } from "~/components/form";
import { SaveImageMenuItem } from "~/components/metric/SaveImageMenuItem";
import { SaveImageButton } from "~/components/SaveImageButton";
import { Spacer } from "~/components/Spacer";
import { UpdateNotes } from "~/components/UpdateNotes";
import { VerticalDotMenu } from "~/components/VerticalDotMenu";
import { ImageCaptureTarget } from "~/contexts/ImageCaptureContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { backgroundBarPlugin } from "~/utils/barBackgroundPlugin";
import dayjs from "~/utils/dayjs";
import { getArrayOfFlywheelMonths } from "~/utils/getArrayOfFlywheelMonths";
import { getBarColour } from "~/utils/getBarColour";
import { getLabelBeingHovered } from "~/utils/getLabelBeingHovered";
import { getUserDisplayName } from "~/utils/getUserDisplayName";
import { handleBarChartHoverForMonth } from "~/utils/handleBarChartHover";

import type {
  ActiveElement,
  ChartEvent,
  Chart as ChartJS,
  ScriptableContext
} from "chart.js/auto";

export const FlywheelGoalDetail = () => {
  const {
    flywheel: flywheelCtx, flywheelSubgoals, flywheelStartMonth, flywheelCycleNotStarted
  } = useSelectedFlywheel();

  const isMobile = useIsMobile();
  // We get the month, update, subgoal, and months within the year based on the month the user chooses - by default, the current month
  const [ selectedMonthStart, setSelectedMonthStart ] = useState(dayjs().subtract(1, "month").startOf("month"));
  const selectedMonthEnd = useMemo(() => selectedMonthStart.add(1, "month"), [ selectedMonthStart ]);

  const selectedSubgoal = useMemo(() => flywheelSubgoals?.find(subgoal => {
    // Get the first monday from start date
    const startMonday = getFirstMondayFromStartDate(selectedMonthStart);

    // Find the subgoal the currently selected month fits into - we do this exclusive of the subgoal endDate [) so that we don't have overlaps
    return startMonday.isBetween(dayjs(subgoal.startDate).startOf("isoWeek"), dayjs(subgoal.endDate).endOf("isoWeek"), "day", "[)");
  }), [ flywheelSubgoals, selectedMonthStart ]);

  const selectedMonthUpdate = useMemo(() => selectedSubgoal?.updates?.find(update => dayjs(update.startDate).isSame(selectedMonthStart, "month")), [ selectedMonthStart, selectedSubgoal?.updates ]);
  // Build an array of weeks of the flywheel - used in the week selector. Pass true to get months UNTIL NOW
  const monthsOfFlywheelYearSelectOptions = useMemo(() => flywheelStartMonth ? getArrayOfFlywheelMonths(flywheelStartMonth, true) : [], [ flywheelStartMonth ]);
  const monthsOfFlywheelYear = useMemo(() => flywheelStartMonth ? getArrayOfFlywheelMonths(flywheelStartMonth) : [], [ flywheelStartMonth ]);
  const monthsOfFlywheelYearLabels = useMemo(() => monthsOfFlywheelYear.map(month => dayjs(month).format("MMM").toUpperCase()), [ monthsOfFlywheelYear ]);
  const allFlywheelGoalUpdates = flywheelSubgoals?.flatMap(subgoal => subgoal.updates);

  const handleChartClick = useCallback((
    event: ChartEvent,
    _elements: ActiveElement[],
    chart: ChartJS
  ) => {
    // Get the label that we clicked on (e.g JAN, FEB, MAR)
    const label = getLabelBeingHovered(event, chart);

    if (!label) return null;

    if (!flywheelStartMonth) return null;

    // Find the index of the selected month
    const selectedMonthIdx = monthsOfFlywheelYearLabels.findIndex(month => month === label);

    // Return if there was no match
    if (selectedMonthIdx === -1) return null;

    // Find the actual month object from monthsOfFlywheelYear
    const selectedMonth = monthsOfFlywheelYear[ selectedMonthIdx ];

    // Check that the month they're trying to select is NOT in the future (available for check-in)
    if (!dayjs(selectedMonth).isAfter(dayjs().subtract(1, "month"), "month")) {
      setSelectedMonthStart(dayjs(selectedMonth));
    }
  }, [
    flywheelStartMonth,
    monthsOfFlywheelYear,
    monthsOfFlywheelYearLabels
  ]);

  const flywheelGoalProgressTargetData = monthsOfFlywheelYear.map(month => {
    const subgoalForMonth = flywheelSubgoals?.find(subgoal => dayjs(month).isBetween(dayjs(subgoal.startDate).startOf("day"), dayjs(subgoal.endDate).endOf("day"), null, "[]"));

    return subgoalForMonth ? Number(subgoalForMonth.goal) : 0;
  });

  const flywheelGoalProgressMonthlyUpdateData = monthsOfFlywheelYear.map(month => {
    if (dayjs(month).isAfter(dayjs().subtract(1, "month"), "month")) return 0;
    // Find update for the given month
    const updateForMonth = allFlywheelGoalUpdates?.find(update => dayjs(update.startDate).isSame(dayjs(month), "month"));

    return updateForMonth ? Number(updateForMonth.value) : 0;
  });

  const flywheelGoalProgressCumulativeData = monthsOfFlywheelYear.map(month => {
    // If the month is current or in the future, don't show cumulative data
    if (dayjs(month).isAfter(dayjs().subtract(1, "month"), "month")) return 0;

    // Find the subgoal this month sits in
    const subgoal = flywheelSubgoals?.find(subgoal => dayjs(month).isBetween(dayjs(subgoal.startDate).startOf("day"), dayjs(subgoal.endDate).endOf("day"), null, "[]"));
    // And find all updates in the given subgoal which are before the current month
    const previousUpdatesInSubgoal = allFlywheelGoalUpdates?.filter(update => dayjs(update.startDate).isBetween(dayjs(subgoal?.startDate).startOf("day"), dayjs(month), null, "[)"));

    // Calculate the cumulative total so far
    // If the flywheel goal is a percentage then return 0 for the total so far
    const cumulativeTotal = flywheelCtx?.latestFlywheelGoal?.unitTypeLabel !== FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ?
      previousUpdatesInSubgoal?.reduce((prev, curr) => prev + Number(curr.value), 0) : 0;

    return cumulativeTotal;
  });

  // Get the background colour for the chart based on the month and if check-in is due
  const getChartBackgroundColour = (index: number) => {
    const currentMonth = monthsOfFlywheelYear[ index ];

    // if the current month has check in due, return the check-in-due colour
    if (selectedSubgoal?.isCheckInDue && dayjs(currentMonth).isSame(selectedMonthStart, "month")) {
      return tailwindBrandColours.brand[ "check-in-due-50" ];
    } else return "#F8F9FC";
  };

  const isCheckInDue = useMemo(() => !selectedMonthUpdate && !!selectedSubgoal, [ selectedMonthUpdate, selectedSubgoal ]);

  return (
    <ImageCaptureTarget
      captureId="main-goal-all"
    >
      <div className="flex flex-col w-full h-goal-container pt-0 lg:pt-10">

        <div className="mt-4 md:mt-0 flex flex-col gap-6 h-full">

          <div className="flex flex-col md:flex-row gap-3 items-center justify-between">

            <p
              className={`text-xl text-pretty font-semibold flex-1 break-words max-w-[500px] ${isMobile ? "w-full" : "w-[80%]"}`}
              data-display-name
            >{flywheelCtx?.name}
            </p>

            <Spacer className="" />

            {!flywheelCycleNotStarted && (

              <div className="min-w-full md:min-w-[30%] shrink-0">
                <SelectInput
                  disabled={monthsOfFlywheelYearSelectOptions.length === 0}
                  value={selectedMonthStart.toString()}
                  options={monthsOfFlywheelYearSelectOptions}
                  renderOptionLabel={value => dayjs(value).format("MMMM YYYY")}
                  placeholder="Choose a month"
                  onValueChange={value => setSelectedMonthStart(dayjs(value))}
                  renderOptionBadge={value => (
                    <FlywheelGoalCheckInBadge
                      monthStart={dayjs(value).startOf("month")}
                      monthEnd={dayjs(value).endOf("month")}
                      subgoal={selectedSubgoal}
                    />
                  )}
                />
              </div>
            )}

            <SaveImageButton
              captureId="main-goal-all"
              className="mobile:hidden shrink-0"
              html2canvasOptions={{
                allowTaint: true,
                useCORS: true,
                onclone(_, element) {
                  // add some padding to the otherwise padding-less container
                  // (!) this doesn't give the ChartJS a chance to resize
                  // properly, therefore pushes it (!!!), so minimise padding where possible (argh!)
                  element.style.padding = "10px 10px 0px 10px";
                  // fixes issue where tiles are stacked on top of each other and
                  // pushes elements off-screen in output image
                  element.querySelector("[data-capture-target=\"main-goal\"]:not(:only-child)")!.parentElement!.style.flexDirection = "row";
                  element.querySelector("[data-display-name]")!.parentElement!.style.flexDirection = "row";
                  element.querySelector("[data-capture-target=\"main-goal-chart\"]:not(:only-child)")!.parentElement!.style.height = document.querySelector("[data-capture-target=\"main-goal-chart\"]")!.clientHeight + "px";
                }
              }}
            />
          </div>

          <div
            className="flex flex-col md:flex-row gap-8 justify-between md:mt-4 flex-wrap"
          >

            <ImageCaptureTarget captureId="main-goal">
              <div
                className="bg-white border-[1.5px] border-solid border-brand-cold-metal-200 flex flex-col rounded-lg flex-1 flex-wrap"
              >
                {flywheelCtx?.latestFlywheelGoal && (
                  <FlywheelGoalTotalCard selectedMonthStart={selectedMonthStart} />
                )}
              </div>
            </ImageCaptureTarget>

          </div>

          {selectedSubgoal && (
            <div
              className="flex flex-col md:flex-row gap-8 justify-between md:mt-4 flex-wrap"
            >
              <ImageCaptureTarget captureId="main-goal">
                <div
                  className={`${isCheckInDue ? "bg-brand-check-in-due-100/25" : "bg-white"} border-[1.5px] border-solid border-brand-cold-metal-200 flex flex-col rounded-lg flex-1 flex-wrap`}
                >
                  {flywheelCtx?.latestFlywheelGoal && (
                    <FlywheelSubgoalCard
                      selectedMonthStart={selectedMonthStart}
                      selectedSubgoal={selectedSubgoal}
                      selectedUpdate={selectedMonthUpdate}
                    />
                  )}
                </div>
              </ImageCaptureTarget>

              <div
                className={`bg-white flex flex-col rounded-xl min-h-[250px] max-h-[400px] w-full min-w-[min(100%,300px)] text-left flex-1 border-[1.5px] border-solid ${selectedMonthUpdate?.notes ? "justify-between" : "justify-end"}`}
              >
                {flywheelCtx?.latestFlywheelGoal && (
                  <UpdateNotes
                    reportedAt={selectedMonthUpdate?.updatedAt}
                    reportedBy={selectedMonthUpdate?.updatedBy}
                    notes={selectedMonthUpdate?.notes}
                  />
                )}
              </div>
            </div>
          )}

          <ImageCaptureTarget captureId="main-goal-chart">
            <div
              className="bg-white border-[1.5px] border-solid border-brand-cold-metal-200 flex flex-col rounded-lg p-6 grow min-h-[250px] max-h-[500px]"
            >
              <div className="flex justify-between">
                <p className="text-lg font-semibold">Performance history</p>

                <VerticalDotMenu
                  buttonClassName="mobile:hidden m-0 -mr-4 md:-mr-8 -mt-1 text-brand-cold-metal-400 hover:text-black"
                >
                  <SaveImageMenuItem
                    captureId="main-goal-chart"
                    html2canvasOptions={{
                      onclone(_, element) {
                        // min/max height classes seem to be tripping up output
                        element.style.minHeight = "unset";
                        element.style.maxHeight = "unset";
                        element.style.height = document.querySelector("[data-capture-target=\"main-goal-chart\"]")!.clientHeight + "px";
                      }
                    }}
                  />
                </VerticalDotMenu>
              </div>

              <div className="p-4 relative h-full">
                <Chart
                  type="bar"
                  plugins={[ backgroundBarPlugin ]}
                  id="performance-history-chart"
                  data={{
                    labels: monthsOfFlywheelYearLabels,
                    datasets: [
                      {
                        type: "line",
                        label: "Target",
                        data: flywheelGoalProgressTargetData,
                        borderColor: "#4B5563",
                        backgroundColor: "#4B5563",
                        borderDash: [ 4, 4 ],
                        borderWidth: 1,
                        pointStyle: "circle",
                        pointRadius: 1.5,
                        tension: 0.1,
                        order: 1,
                        xAxisID: "x1"
                      },
                      {
                        type: "bar",
                        label: "Cumulative total",
                        data: flywheelGoalProgressCumulativeData,
                        backgroundColor: (ctx: ScriptableContext<"bar">) => {
                          const barMonthStart = monthsOfFlywheelYear[ ctx.dataIndex ];

                          return getBarColour(dayjs(barMonthStart), selectedMonthStart, selectedMonthEnd, selectedSubgoal?.isCheckInDue || false, selectedMonthUpdate?.isHealthy, true);
                        },
                        borderRadius: 5,
                        order: 2
                      },
                      {
                        type: "bar",
                        label: "Check-in value",
                        data: flywheelGoalProgressMonthlyUpdateData,
                        backgroundColor: (ctx: ScriptableContext<"bar">) => {
                          const barWeekStart = monthsOfFlywheelYear[ ctx.dataIndex ];

                          return getBarColour(dayjs(barWeekStart), selectedMonthStart, selectedMonthEnd, selectedSubgoal?.isCheckInDue || false, selectedMonthUpdate?.isHealthy);
                        },
                        borderRadius: 5,
                        order: 3
                      }
                    ]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        display: false,
                        border: { display: false },
                        grid: { display: false },
                        stacked: true
                      },
                      x: {
                        grid: { display: false },
                        border: { display: false },
                        stacked: true,
                        offset: true,
                        ticks: {
                          font: ctx => {
                            const tickWeek = dayjs(monthsOfFlywheelYear[ ctx.index ]);

                            if (tickWeek.isSame(selectedMonthStart, "month")) {
                              return {
                                weight: "bold",
                                size: 16
                              };
                            }

                            return undefined;
                          }
                        }
                      },
                      x1: {
                        border: { display: false },
                        grid: { display: false },
                        stacked: true,
                        offset: true,
                        ticks: {
                          callback(_, index) {
                            // Only show labels for the central bars in each subgoal
                            switch (index) {
                              case 1: return "Q1";
                              case 4: return "Q2";
                              case 7: return "Q3";
                              case 10: return "Q4";
                              default: return "";
                            }
                          },
                          font: ctx => {
                            const tickMonth = dayjs(monthsOfFlywheelYear[ ctx.index ]);

                            // Make the subgoal name bold if the month is within the selected subgoal
                            if (tickMonth.isBetween(dayjs(selectedSubgoal?.startDate).startOf("day"), dayjs(selectedSubgoal?.endDate).endOf("day"), "month", "[]")) {
                              return {
                                weight: "bold",
                                size: 18
                              };
                            }

                            return undefined;
                          }
                        }
                      }
                    },
                    plugins: {
                      backgroundBar: {
                        borderSkipped: true,
                        colour: getChartBackgroundColour
                      },
                      legend: { display: false },
                      title: { display: false }
                    },
                    onClick: handleChartClick,
                    onHover: (event, _elements, chart) => handleBarChartHoverForMonth(event, chart, flywheelStartMonth)
                  }}
                />
              </div>
            </div>
          </ImageCaptureTarget>

          {flywheelCtx?.latestFlywheelGoal?.owner && (
            <div className="w-full pb-5">
              <p className="text-xs text-brand-cold-metal-500 font-semibold text-right">Assigned to</p>

              <div className="flex flex-row gap-2 items-center text-sm mt-1 justify-end">
                <Avatar
                  user={flywheelCtx?.latestFlywheelGoal?.owner}
                  px={24}
                />

                <p>{getUserDisplayName(flywheelCtx?.latestFlywheelGoal?.owner)}</p>
              </div>
            </div>
          )}

        </div>

      </div>
    </ImageCaptureTarget>
  );
};