import MetricImg from "~/assets/illustrations/metrics.png";
import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { useIsMobile } from "~/hooks/useIsMobile";

export const SpeakToTeam: React.FC<OnboardingContentStepProps> = ({
  onNextStep, changeStep, onBack
}) => {
  const isMobile = useIsMobile();

  return (
    <OnboardingContentWrapper
      changeStep={changeStep}
      rightContent={(
        <img
          src={MetricImg}
          className="absolute inset-y-0 top-5 object-contain desktop:max-w-[min(32vmax,500px)] px-6 desktop:absolute-center w-full h-full"
        />
      )}
      leftContent={(
        <div className="w-full flex-1 flex flex-col">

          <div className="flex flex-col gap-y-4 mt-4 rounded-lg bg-brand-cold-metal-50 p-6">
            <div className="flex flex-row gap-x-3 items-center justify-between">

              <h3 className="text-brand-cold-metal-800 font-semibold">This is my first flywheel</h3>

              <Icon.LifeBuoy className="text-brand-cold-metal-500" />
            </div>

            <p className="text-brand-cold-metal-500 text-sm pb-4">If you're new to the flywheel concept or strategic goal setting, we recommend booking a discovery call, where a Roda team member will help set up your first flywheel.</p>

            <Button
              title="Book a discovery call (recommended)"
              onClick={() => window.location.href = "https://calendly.com/matt-spry-rodastrategy/roda-set-up-support-call"}
              className="bg-brand-cold-metal-900 hover:contrast-75 text-brand-cold-metal-100"
            />

          </div>

          <div className="flex mt-6 flex-col gap-y-4 rounded-lg bg-brand-cold-metal-50 p-6">
            <div className="flex flex-row gap-x-3 items-center justify-between">

              <h3 className="text-brand-cold-metal-800 font-semibold">I have goals & metrics ready to go</h3>

              <Icon.Target className="text-brand-cold-metal-500" />
            </div>

            <p className="text-brand-cold-metal-500 text-sm pb-4">If you're confident in your strategy and metrics, then we recommend you continue with self-guided setup. For questions, click "Speak to the team" at the top-right of any screen.</p>

            <Button
              title="Start self-guided setup"
              onClick={onNextStep}
              className="bg-brand-cold-metal-200 hover:contrast-75 text-brand-cold-metal-900"
            />
          </div>

          <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3" : "flex-row items-center"}`}>

            <Button
              title="Back"
              onClick={onBack}
              className="mt-6 bg-brand-cold-metal-200 shrink hover:contrast-75 text-brand-cold-metal-900"
            />
          </div>
        </div>
      )}
    />

  );
};