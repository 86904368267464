/**
 * While in UTC mode, all display methods will display in UTC time instead of local time.
 * Additionally, while in UTC mode, all getters and setters will internally use the Date#getUTC* and Date#setUTC* methods instead of the Date#get* and Date#set* methods.
 */
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

import { getFakeNowDate } from "~/utils/getCurrentFakeDateFromStorage";

// Extend dayjs
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);
dayjs.extend(RelativeTime);
dayjs.extend(isSameOrBefore);

/**
 * Function that returns a fake NOW
 * Always return the actual now on prod
 * @param date
 */
const fakeNowDayjs = (date?: Date | dayjs.Dayjs | string | null) => {
  const isProd = import.meta.env.VITE_STAGE === "production";

  if (isProd) {
    return dayjs(date); // Always use original dayjs for prod
  } else {
    const now = getFakeNowDate();

    return dayjs(date || now);
  }
};

export default fakeNowDayjs;