import { enumUserRole } from "@roda/graphql/genql";
import {
  useEffect,
  useMemo
} from "react";
import {
  Outlet,
  useNavigate,
  useParams
} from "react-router-dom";
import { useSpringCarousel } from "react-spring-carousel";

import { Button } from "~/components/Button";
import { MetricStatusDots } from "~/components/flywheel/roda/MetricStatusDots";
import { withStepTheme } from "~/components/flywheel/roda/RodaFlywheel";
import { StatusBubble } from "~/components/flywheel/roda/StatusBubble";
import { Icon } from "~/components/Icon";
import { routes } from "~/constants/routes";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import { getHealthStatusColour } from "~/utils/getHealthStatusColour";
import { getStepStatus } from "~/utils/getStepStatus";

interface FlywheelOutletProps {
  showStepNav: boolean;
  stepId?: string;
}

export const FlywheelOutlet: React.FC<FlywheelOutletProps> = ({ showStepNav, stepId }) => {
  const params = useParams() as { stepId: string, metricId?: string };
  const { flywheel: flywheelCtx } = useSelectedFlywheel();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user: currentUser } = useCurrentUser();
  const { companyId } = useParams();

  const {
    companies, currentCompany, setCurrentCompany
  } = useRodaAdminCompaniesContext();

  /** Load in the admin company from the path param, if there isn't one loaded already or it's the wrong company */
  useEffect(() => {
    if (currentUser?.role === enumUserRole.RODA_ADMIN && (!currentCompany || (companyId && currentCompany.id !== companyId))) {
      if (!companyId || !companies?.find(company => company.id === companyId)) {
        navigate(routes.rodaAdminOrganisationManagement);
      } else {
        setCurrentCompany(companyId);
      }
    }
  }, [
    companies,
    companyId,
    currentCompany,
    navigate,
    setCurrentCompany,
    currentUser?.role
  ]);

  // Only want to track the initial stepId on first load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialId = useMemo(() => flywheelCtx?.steps?.findIndex(s => s.id === stepId), [ flywheelCtx?.steps ]);

  // Setup the Steps carousel for mobile
  const {
    carouselFragment, slideToItem, useListenToCustomEvent, getIsNextItem, slideToNextItem, getIsPrevItem, slideToPrevItem
  } = useSpringCarousel({
    withLoop: true,
    initialActiveItem: Math.abs(initialId || 0),
    gutter: 20,
    items: flywheelCtx?.steps?.map(step => {
      const status = getStepStatus(step);
      const isSelected = step.id === stepId;

      return {
        id: step.id,
        renderItem: (
          <button
            key={step.id}
            onClick={() => {
              // By sliding next/prev instead of item.id,
              // we ensure the carousel only moves once each time
              if (getIsNextItem(step.id)) {
                slideToNextItem();
              }

              if (getIsPrevItem(step.id)) {
                slideToPrevItem();
              }

              navigate(routes.step(step.id, currentCompany ? currentCompany.id : undefined));
            }}
            style={{ ...withStepTheme(getHealthStatusColour(status)) }}
            className={`${isSelected ? "opacity-100 scale-125" : "opacity-20"} py-1 flex-1 whitespace-nowrap flex flex-col group-hover:-translate-y-1 transition-all items-center text-body-medium gap-1`}
          >
            <MetricStatusDots
              step={step}
            />

            <p
              className={cn(
                "font-semibold text-2xl max-w-[200px] text-wrap"
              )}
              style={{ "--tsv-chars": (step.alias ?? step.name).length } as React.CSSProperties}
            >{step.alias ?? step.name}
            </p>

            <StatusBubble
              status={status}
            />
          </button>
        )
      };
    }) || [
      {
        id: "--", // must render something!
        renderItem: (
          <></>
        )
      }
    ]
  });

  // Listen to events on the Step carousel (mobile-only)
  useListenToCustomEvent(data => {
    switch (data.eventName) {
      // when we start sliding, let's navigate to the appropriate route
      case "onSlideStartChange": {
        slideToItem(data.nextItem.id);
        navigate(routes.step(data.nextItem.id, currentCompany ? currentCompany.id : undefined));
        break;
      }
    }
  });

  return (

    <div
      style={{ animationDelay: "1s" }}
      className="absolute animated animatedFadeInUp fadeInUp top-0 flex flex-1 gap-4 w-full flex-col md:flex-row md:*:min-w-0"
    >

      {!isMobile && (
        <section
          className={` flex flex-col w-full ${showStepNav ? "md:w-[25%]" : "w-0 absolute"} p-4`}
          id="goal-back-header"
        >
          <div>
            <Button
              title="Back"
              iconComponent={<Icon.ChevronLeft />}
              className="bg-brand-cold-metal-200 text-brand-cold-metal-800 font-normal px-1 py-2 text-xs"
              onClick={() => navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined))}
            />
          </div>

        </section>
      )}

      <section
        className="flex flex-col lg:p-4 lg:pr-8 w-full max-md:pb-20"
      >
        {!isMobile && showStepNav && (
          <div
            className="flex flex-row gap-3 items-center sticky pb-4"
            id="step-outlet-menu"
          >
            {flywheelCtx?.steps?.map(step => (
              <Button
                key={step.id}
                title={step.alias ?? step.name}
                className={`text-size-variable [&>*]:truncate !px-2.5  [--tsv-min:12px] [--tsv-max:24px] py-2 text-xs  font-normal ${step.id === params.stepId ? "bg-brand-cold-metal-900 text-brand-cold-metal-50" : "bg-transparent text-brand-cold-metal-900"}`}
                onClick={() => navigate(routes.step(step.id, currentCompany ? currentCompany.id : undefined))}
              />
            ))}
          </div>
        )}

        <div
          className={`w-full overflow-hidden grow-0 ${(isMobile && stepId) ? "block" : "hidden"}`}
          id="mobile-flywheel-header"
        >
          <div className="flex flex-row mt-[33%] sm:mt-[30%] md:mt-[25%] mx-auto items-center w-44 sm:w-52 md:w-64  pb-4">
            {carouselFragment}
          </div>
        </div>

        <div className="p-4 lg:p-0 flex-1">
          {/*
            This Outlet renders the nested route!
            So this is where the Step, FlywheelGoal and MetricDetail pages are rendered into
          */}
          <Outlet />
        </div>
      </section>
    </div>

  );
};
