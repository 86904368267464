import { zodResolver } from "@hookform/resolvers/zod";
import {
  availableFlywheelTemplates,
  flywheelGoalUnits,
  FlywheelTemplateUnitTypeLabelEnum,
  UpdateFrequency
} from "@roda/shared/types";
import { nanoid } from "nanoid";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/components/Button";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { CustomiseFlywheelGoal } from "~/components/customise-flywheel-goal/CustomiseFlywheelGoal";
import { CustomiseFlywheelTemplate } from "~/components/customise-flywheel-goal/CustomiseFlywheelTemplate";
import { FlywheelGoalCard } from "~/components/customise-flywheel-goal/FlywheelGoalCard";
import { FlywheelGoalPreview } from "~/components/customise-flywheel-goal/FlywheelGoalPreview";
import { FlywheelTemplateCard } from "~/components/customise-flywheel-goal/FlywheelTemplateCard";
import {
  Input,
  InputLabel,
  SelectInput
} from "~/components/form";
import { Icon } from "~/components/Icon";
import { NoWrap } from "~/components/NoWrap";
import {
  DisplayModes,
  IntroFlywheel
} from "~/components/onboarding/intro-flywheel/IntroFlywheel";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { Spacer } from "~/components/Spacer";
import { dummyIntroFlywheel } from "~/constants/misc";
import {
  useCustomiseFlywheelGoalDispatch,
  useCustomiseFlywheelGoalState
} from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useUpdateFlywheel } from "~/hooks/flywheel";
import { useCreateFlywheelGoalAndSubgoals } from "~/hooks/flywheel-goal";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { breakDownSubgoals } from "~/utils/breakDownSubgoals";
import { constructUtcDate } from "~/utils/dates/constructUtcDate";
import { deduplicateByName } from "~/utils/deduplicate-by-name";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getFlywheelTemplateInfo } from "~/utils/getFlywheelTemplateInfo";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { smartLowercase } from "~/utils/toLowercase";
import { cleanNumberInput } from "~/utils/validation/cleanNumberInput";

import type { CreateSubgoalType } from "@roda/core/flywheel-goal/createFlywheelGoalAndSubgoals";
import type {
  Currency,
  Subgoal
} from "@roda/graphql/genql";
import type {
  FlywheelGoalEnum,
  FlywheelGoalUnit
} from "@roda/shared/types";

export const FlywheelGoalSchema = z.object({
  ownerEmail: z.string().trim().min(1, "Required").email().max(100, "Maximum 100 characters"),
  updateFrequency: z.string().trim().min(1, "Required"),
  goal: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Please choose a valid goal"),
  flywheelTemplateId: z.number().min(1, "Required").optional()
});

export const FlywheelGoal: React.FC<OnboardingContentStepProps> = ({
  onNextStep, changeStep, onBack, createFlywheelMode
}) => {
  const { flywheel, refetchFlywheel } = useSelectedFlywheel();
  const { user } = useCurrentUser();
  const [ subgoals, setSubgoals ] = useState<CreateSubgoalType[] | null>([]);
  const onboardingDispatch = useOnboardingDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();

  const {
    isCustomisingMeasurement, isCustomisingTemplate, flywheelGoal, flywheelTemplate
  } = useCustomiseFlywheelGoalState();

  const { assertGraphQLSuccess, handleRodaError } = useError();
  const [ createFlywheelGoalRes, createFlywheelGoalReq ] = useCreateFlywheelGoalAndSubgoals();
  const [ updateFlywheelRes, updateFlywheelReq ] = useUpdateFlywheel();
  const [ showOwnerConfirmation, setShowOwnerConfirmation ] = useState(false);
  const isMobile = useIsMobile();
  const flywheelContainerRef = useRef<HTMLDivElement>(null);
  const [ flywheelSize, setFlywheelSize ] = useState(0);

  /**
   * Function for getting the flywheel template name by id
   */
  const getFlywheelTemplateName = useCallback((id: number) => {
    const name = availableFlywheelTemplates.find(i => +i.id === id)?.name;

    return name as FlywheelGoalEnum;
  }, [ ]);

  useEffect(() => {
    const updateFlywheelSize = () => {
      if (flywheelContainerRef.current) {
        const containerHeight = Math.min(flywheelContainerRef.current.offsetHeight, flywheelContainerRef.current.offsetWidth * 0.50);
        const size = containerHeight - 60;

        setFlywheelSize(size);
      }
    };

    // Call the update function initially and on window resize
    updateFlywheelSize();
    window.addEventListener("resize", updateFlywheelSize);

    return () => {
      window.removeEventListener("resize", updateFlywheelSize);
    };
  }, [ isMobile, flywheelContainerRef.current?.offsetHeight ]);

  const {
    flywheel: onboardingFlywheel, flywheelGoal: onboardingFlywheelGoal, company: onboardingCompany, invitedUsers
  } = useOnboardingState();

  // Get an existing flywheel - if in createFlywheelMode always use the onboardingFlywheel
  const existingFlywheel = useMemo(() => createFlywheelMode ? onboardingFlywheel : onboardingFlywheel || flywheel, [
    createFlywheelMode,
    flywheel,
    onboardingFlywheel
  ]);

  // Get an existing flywheel goal - if in createFlywheelMode always use the onboardingFlywheelGoal
  const existingFlywheelGoal = useMemo(() => createFlywheelMode ? onboardingFlywheelGoal : onboardingFlywheelGoal || flywheel?.latestFlywheelGoal, [
    createFlywheelMode,
    flywheel?.latestFlywheelGoal,
    onboardingFlywheelGoal
  ]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<z.infer<typeof FlywheelGoalSchema>>({
    resolver: zodResolver(FlywheelGoalSchema),
    defaultValues: {
      goal: existingFlywheelGoal?.goal,
      ownerEmail: onboardingFlywheelGoal?.ownerEmail || user?.email || undefined,
      updateFrequency: UpdateFrequency.QUARTERLY
    },
    shouldFocusError: false
  });

  const watchedOwnerEmail = watch("ownerEmail");
  const watchedGoal = watch("goal");
  const alreadyInvited = useMemo(() => invitedUsers.find(userEmail => userEmail === watchedOwnerEmail), [ invitedUsers, watchedOwnerEmail ]);

  // Get cap percentage
  const exceedsPercentageMax = useMemo(() => flywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE && (flywheelGoal.cap !== null && flywheelGoal.cap !== undefined) ? +watchedGoal > flywheelGoal.cap : false, [
    flywheelGoal?.cap,
    flywheelGoal?.unitTypeLabel,
    watchedGoal
  ]);

  const handleSelectTemplate = useCallback((id: number) => {
    const flywheelTemplateInfo = getFlywheelTemplateInfo({ flywheelTemplateId: id });

    if (flywheelTemplateInfo) {
      customiseFlywheelGoalDispatch({
        type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
        flywheelTemplate: {
          id,
          name: flywheelTemplateInfo.flywheel.name,
          steps: flywheelTemplateInfo.flywheel.steps.map(step => ({
            ...step,
            id: nanoid(),
            metrics: []
          }))
        }
      });
    }
  }, [ customiseFlywheelGoalDispatch ]);

  const availableFlywheelGoalUnits = useMemo(() => flywheelTemplate?.id ? flywheelGoalUnits[ getFlywheelTemplateName(flywheelTemplate?.id) ] : deduplicateByName<FlywheelGoalUnit>([
    ...flywheelGoalUnits.Growth,
    ...flywheelGoalUnits.Impact,
    ...flywheelGoalUnits[ "Operations Management" ]
  ]), [ flywheelTemplate?.id, getFlywheelTemplateName ]);

  const handleSelectUnit = useCallback((name: string) => {
    // Find the right unit object based on the name that was picked
    const unit = availableFlywheelGoalUnits.find(unit => unit.name === name);

    customiseFlywheelGoalDispatch({
      type: "SET_SELECTED_FLYWHEEL_GOAL",
      flywheelGoal: unit
    });
  }, [ availableFlywheelGoalUnits, customiseFlywheelGoalDispatch ]);

  const unitIcon = useMemo(() => {
    return getUnitSymbol(flywheelGoal?.unitTypeLabel, onboardingCompany?.currency as Currency);
  }, [ onboardingCompany?.currency, flywheelGoal ]);

  const handleCreateSubgoals = useCallback((goal?: string) => {
    goal && setValue("goal", goal);
    const achieveByDate = existingFlywheelGoal?.achieveBy;
    const mainGoal = Number(goal) || watch("goal");
    const flywheelGoalTypeLabel = flywheelGoal?.unitTypeLabel || FlywheelTemplateUnitTypeLabelEnum.COUNT;

    if (!flywheelGoalTypeLabel) {
      return;
    }

    if (achieveByDate && mainGoal && flywheelGoalTypeLabel) {
      setSubgoals(breakDownSubgoals({
        achieveByDate,
        mainGoal: mainGoal.toString(),
        timeframe: "QUARTERLY", // default to quarters for now
        typeLabel: flywheelGoalTypeLabel as FlywheelTemplateUnitTypeLabelEnum
      }));
    }
  }, [
    setValue,
    existingFlywheelGoal?.achieveBy,
    watch,
    flywheelGoal
  ]);

  // If there's a goal already create subgoals in local state
  useEffect(() => {
    if (existingFlywheelGoal?.goal) {
      handleCreateSubgoals(existingFlywheelGoal?.goal);
    }
  }, [ existingFlywheelGoal, handleCreateSubgoals ]);

  /**
   * Function for checking whether to show the owner invite confirmation popup
   */
  const handleCheckOwnerEmail = () => {
    if (watchedOwnerEmail.length && !errors.ownerEmail?.message) {
      const ownedByCurrentUser = watchedOwnerEmail === user?.email;

      setShowOwnerConfirmation(!ownedByCurrentUser && !alreadyInvited);
    }
  };

  // Submit handler
  const onSubmit = handleSubmit(fields => {
    const flywheelId = existingFlywheel?.id;
    const flywheelGoalId = existingFlywheelGoal?.id;
    const flywheelTemplateName = flywheelTemplate?.name;

    // Update the flywheel details in onboarding state
    onboardingDispatch({
      type: "SET_FLYWHEEL",
      flywheel: {
        ...onboardingFlywheel,
        updateFrequency: fields.updateFrequency,
        flywheelTemplateId: flywheelTemplate?.id,
        steps: flywheelTemplate?.steps.map((step, stepIdx) => ({
          ...step,
          // Keep any metrics that we had previously set up for this step
          metrics: existingFlywheel?.steps?.find(s => s.id === step.id)?.metrics || [],
          stepIdx
        })),
        name: flywheelTemplateName
      }
    });

    // Update the flywheel goal reporting cycle details in onboarding state
    onboardingDispatch({
      type: "SET_FLYWHEEL_GOAL",
      flywheelGoal: {
        ...onboardingFlywheelGoal,
        subgoals: subgoals as Subgoal[],
        goal: fields.goal.toString(),
        name: flywheelGoal?.name,
        unitName: flywheelGoal?.unitName,
        unitType: "number", // will always be a currency at this point
        unitTypeLabel: "Currency",
        unitDescription: flywheelGoal?.unitDescription,
        cap: flywheelGoal?.cap,
        ownerEmail: fields.ownerEmail
      }
    });

    // If current user is not the owner and they haven't been invited already add user to invitedUsers array of onboarding state
    if (watchedOwnerEmail !== user?.email && !alreadyInvited) {
      onboardingDispatch({
        type: "ADD_INVITED_USER",
        userEmail: fields.ownerEmail
      });
    }

    if (!!subgoals && flywheelId && existingFlywheelGoal?.achieveBy) {
      if (flywheelGoal && !showOwnerConfirmation) {
        // Create flywheel goal and subgoals for flywheel
        createFlywheelGoalReq({
          flywheelGoalId: flywheelGoalId ? +flywheelGoalId : undefined,
          flywheelId: +flywheelId,
          achieveBy: constructUtcDate(existingFlywheelGoal?.achieveBy),
          subgoals,
          goal: fields.goal.toString(),
          name: flywheelGoal.name!,
          steps: flywheelTemplate?.steps?.map((step, idx) => {
            return {
              flywheelId: +flywheelId,
              order: step.order || idx,
              name: step?.name || "",
              metrics: onboardingFlywheel?.steps?.find(s => s.id === step.id)?.metrics?.map(metric => ({
                fromDate: metric.fromDate || "",
                name: metric.name || "",
                ownerEmail: metric.ownerEmail || "",
                target: metric.target || "",
                unitDescription: metric.unitDescription || "",
                unitDisplay: metric.unitDisplay || "",
                unitTargetType: metric.unitTargetType || "",
                unitType: metric.unitType || "",
                unitTypeLabel: metric.unitTypeLabel || "",
                cap: metric.cap,
                reportingWindowTiming: metric.reportingWindowTiming,
                slug: metric.slug || "",
                toDate: metric.toDate,
                unitName: metric.unitName || ""
              })) || []
            };
          }) || [],
          unitName: flywheelGoal.unitName!,
          unitType: "number",
          unitTypeLabel: flywheelGoal.unitTypeLabel || FlywheelTemplateUnitTypeLabelEnum.COUNT,
          unitDisplay: flywheelGoal.unitDisplay || "quarterly",
          unitDescription: flywheelGoal.unitDescription!,
          cap: flywheelGoal.cap || null,
          ownerEmail: fields.ownerEmail,
          updateFrequency: "MONTHLY", // Default to monthly - they will be able to change this in the next step
          fromDate: subgoals[ 0 ].startDate// Start of the first subgoal
        }).then(res => {
          assertGraphQLSuccess(res);
          const responseData = res.data?.createFlywheelGoalAndSubgoals;

          if (responseData) {
            onboardingDispatch({
              type: "SET_FLYWHEEL_GOAL",
              flywheelGoal: {
                ...onboardingFlywheelGoal,
                id: responseData.id,
                subgoals: responseData.subgoals as Subgoal[],
                checkInSubgoal: responseData.checkInSubgoal as Subgoal,
                goal: responseData.goal,
                name: responseData.name,
                unitName: responseData.unitName,
                unitType: responseData.unitType,
                unitTypeLabel: responseData.unitTypeLabel,
                unitDescription: responseData.unitDescription,
                cap: responseData.cap
              }
            });
          }

          // Update flywheel
          updateFlywheelReq({
            flywheelId: +flywheelId,
            name: flywheelTemplateName
          }).then(res => {
            assertGraphQLSuccess(res);
            refetchFlywheel();
            // Move to the next step
            onNextStep();
          }).catch(e => {
            handleRodaError(e, "Something went wrong. Please try again.");
          });
        });
      }
    }
  });

  const goalInnerValue = formatGoalNumber(+watch("goal") || 0, unitIcon, {
    stripTrailingZeros: true,
    shouldCompact: true
  });

  const goalInnerContent = (
    <div className="flex flex-col flex-center min-h-[--size] w-full gap-4 text-brand-cold-metal-900 !pointer-events-none [&>*]:pointer-events-auto @container">
      <div className="flex flex-center flex-col pb-2 gap-1">
        <p
          className="font-bold text-brand-cold-metal-900 text-2xl md:text-3xl lg:text-4xl xl:text-5xl"
          // just eyeballing the font size here
          style={{ fontSize: `clamp(14px, calc((140/${goalInnerValue.length}) * 1cqw), 50px)` }}
        >{goalInnerValue}
        </p>
      </div>
    </div>
  );

  return (
    <>
      <OnboardingContentWrapper
        createFlywheelMode={createFlywheelMode}
        hideLogo={createFlywheelMode}
        changeStep={changeStep}
        rightContent={isCustomisingMeasurement ? (
          <div
            ref={flywheelContainerRef}
            className={`flex items-center h-full flex-1 justify-center shrink ${isMobile ? "max-h-[40vh] max-w-[100dvw] overflow-x-visible pb-10" : "overflow-visible"}`}
          >
            <FlywheelGoalPreview flywheelGoal={flywheelGoal} />
          </div>
        ) : (
          <div
            ref={flywheelContainerRef}
            className={`flex items-center h-full flex-1 justify-center shrink ${isMobile ? "max-h-[40vh] max-w-[100dvw] overflow-x-visible pb-10" : "overflow-visible"}`}
          >
            <IntroFlywheel
              innerContent={goalInnerContent}
              size={flywheelSize}
              flywheel={flywheelTemplate || dummyIntroFlywheel}
              displayMode={DisplayModes.STEPS}
            />
          </div>
        )}

        leftContent={(
          <div className="w-full flex flex-col flex-1 *:min-h-0">
            {isCustomisingMeasurement ? (
              <CustomiseFlywheelGoal />
            ) : isCustomisingTemplate ? (
              <CustomiseFlywheelTemplate />
            ) : (

              <form
                onSubmit={onSubmit}
                className="flex flex-col flex-1 *:min-h-0 gap-5"
              >

                <div className="flex flex-col gap-y-5 *:min-h-0">
                  {flywheelTemplate ? (
                    <div className="gap-2 flex flex-col">

                      <InputLabel
                        htmlFor=""
                      >
                        Your main goal
                      </InputLabel>

                      <FlywheelTemplateCard
                        onClick={() => customiseFlywheelGoalDispatch({ type: "CUSTOMISE_TEMPLATE" })}
                      />
                    </div>
                  ) : (
                    <>
                      <p className="text-sm opacity-80">
                        Your goal could represent a strategic outcome (e.g., growth or customer acquisition), an operational outcome (e.g., utilisation), or a process outcome (e.g., quality).
                      </p>

                      <p className="text-sm opacity-80">
                        Choose from suggested goals in the dropdown, each with a set of templated steps to get you started, or set your own goal and build your steps from scratch.
                      </p>

                      <p className="text-sm opacity-80">
                        This is adjustable, so it doesn't need to be perfect right away.
                      </p>

                      <SelectInput
                        value=""
                        onValueChange={val => handleSelectTemplate(+val)}
                        label="What is your main goal?"
                        placeholder="*Pick a goal*"
                  // Render the Name of the available templates
                        renderOptionLabel={val => availableFlywheelTemplates.find(i => +i.id === +val)?.name || ""}
                        options={availableFlywheelTemplates.map(e => e.id.toString()) ?? []}
                        optionsDescriptions={availableFlywheelTemplates.map(e => e.description) ?? []}
                        renderButton={(
                          <Button
                            title="Create a custom goal"
                            iconComponent={<Icon.PlusCircle />}
                            className="bg-brand-cold-metal-100 m-2 min-h-10 text-brand-cold-metal-800"
                            onClick={() => {
                              customiseFlywheelGoalDispatch({
                                type: "CUSTOMISE_TEMPLATE",
                                flywheelTemplate: {
                                  name: "",
                                  id: null,
                                  steps: [
                                    {
                                      name: "",
                                      order: 1,
                                      metrics: [],
                                      id: nanoid(),
                                      description: ""
                                    },
                                    {
                                      name: "",
                                      order: 2,
                                      metrics: [],
                                      id: nanoid(),
                                      description: ""
                                    },
                                    {
                                      name: "",
                                      order: 3,
                                      metrics: [],
                                      id: nanoid(),
                                      description: ""
                                    },
                                    {
                                      name: "",
                                      order: 4,
                                      metrics: [],
                                      id: nanoid(),
                                      description: ""
                                    },
                                    {
                                      name: "",
                                      order: 5,
                                      metrics: [],
                                      id: nanoid(),
                                      description: ""
                                    }

                                  ]
                                }
                              });
                            }}
                          />
                        )}
                      />
                    </>
                  )}

                  {flywheelTemplate && (
                    <>
                      {flywheelGoal ? (
                        <div className="gap-2 flex flex-col">

                          <InputLabel
                            htmlFor=""
                          >
                            How will you measure your goal?
                          </InputLabel>

                          <FlywheelGoalCard
                            onClick={() => customiseFlywheelGoalDispatch({ type: "CUSTOMISE_GOAL" })}
                          />
                        </div>
                      ) : (
                        <SelectInput
                          value=""
                          onValueChange={val => handleSelectUnit(val)}
                          label="How will you measure your goal?"
                          placeholder="*Pick a measure*"
                          options={availableFlywheelGoalUnits.map(unit => unit.name)}
                          optionsDescriptions={availableFlywheelGoalUnits.map(unit => unit.unitDescription)}
                          renderButton={(
                            <Button
                              title="Create new measurement"
                              iconComponent={<Icon.PlusCircle />}
                              className="bg-brand-cold-metal-100 m-2 min-h-10 text-brand-cold-metal-800"
                              onClick={() => {
                                customiseFlywheelGoalDispatch({ type: "CUSTOMISE_GOAL" });
                              }}
                            />
                          )}
                        />
                      )}

                    </>
                  )}

                  {(flywheelGoal) && (
                    <>
                      <Input
                        {...register("ownerEmail")}
                        label="Who is accountable for this?"
                        name="ownerEmail"
                        inputMode="email"

                        hasError={!!errors.ownerEmail}
                        errorMessage={errors.ownerEmail?.message}
                        onBlur={handleCheckOwnerEmail}
                        suggestions={invitedUsers}
                        onSuggestionSelect={val => setValue("ownerEmail", val, {
                          shouldDirty: true,
                          shouldTouch: true
                        })}
                      />

                      <Input
                        {...register("goal", {
                          onChange: e => {
                            const cleanedValue = cleanNumberInput(e.target.value);

                            setValue("goal", cleanedValue);
                            handleCreateSubgoals(cleanedValue);
                          }
                        })}
                        label={`Enter the target ${smartLowercase(flywheelGoal?.name)} you'd like to achieve by the end of the full cycle of your flywheel. `}
                        name="goal"
                        min={1}
                        id="goal"
                        inputMode="numeric"
                        placeholder="Enter your goal"
                        autoComplete="off"
                        autoCorrect="off"
                        iconPosition={unitIcon === "%" ? "right" : "left"}
                        maxLength={14}
                        iconComponent={<p>{unitIcon}</p>}
                        hasError={!!errors.goal || exceedsPercentageMax}
                        errorMessage={exceedsPercentageMax ? `Max of ${flywheelGoal?.cap}% reached` : errors.goal?.message}
                      />

                    </>
                  )}

                </div>

                {isMobile && (<Spacer />)}

                <div className={`flex gap-x-3 self-start pb-5 ${isMobile ? "flex-col *:min-h-0 w-full gap-3" : "flex-row"} ${isMobile && watch("goal") ? "" : "mt-10"}`}>
                  {onBack && (
                    <Button
                      title="Back"
                      onClick={onBack}
                      className="bg-brand-cold-metal-200 hover:contrast-75 text-brand-cold-metal-900"
                    />
                  )}

                  <Button
                    title="Next"
                    type="submit"
                    disabled={!flywheelGoal || exceedsPercentageMax}
                    className={`px-6 ${isMobile ? "order-first" : ""}`}
                    loading={createFlywheelGoalRes.fetching || updateFlywheelRes.fetching}
                  />
                </div>

              </form>
            )}

          </div>
        )}
      />

      {/* Owner Email verification modal */}
      <ConfirmationPopup
        isOpen={showOwnerConfirmation}
        onContinue={() => setShowOwnerConfirmation(false)}
        continueText="Okay"
        title="User will be invited"
        text={(
          <>
            This will invite
            {" "}

            <NoWrap
              wrap
              className="bg-gray-300/50 box-decoration-clone rounded px-0.5"
            >
              {getValues("ownerEmail")}
            </NoWrap>

            {" "}
            to your organisation as an admin. When they accept your invitation, they will be responsible for posting updates for this flywheel goal.
          </>
        )}
        iconComponent={(
          <Icon.MailOpen
            size={28}
            className="text-brand-cold-metal-700"
          />
        )}
      />
    </>

  );
};
