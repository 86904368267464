export function smartLowercase(input?: string): string {
  if (!input) {
    return "";
  }

  if (input === input.toUpperCase()) {
    return input; // return as-is if the whole string is uppercase
  }

  return input.charAt(0).toLowerCase() + input.slice(1);
}